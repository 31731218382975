<script>
export default {
    data() {
        return {
            stats: {
                user: 0,
            },
            lang: {
                iso: this.$store.state.lang,
                translation: this.$translation,
            },
        };
    },
    methods: {
        Translate(name) {
            var ctx = this;
            if (ctx.lang.translation[ctx.lang.iso][name])
                return ctx.lang.translation[ctx.lang.iso][name];
            else return "None";
        },
    },
    mounted() {
        var ctx = this;
        ctx.axios
            .get(ctx.end_point + "/auth/stats", {
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
            .then(function (res) {
                ctx.stats.user = res.data.users;
            });

        ctx.subscribe = ctx.$store.subscribe((mutation) => {
            if (mutation.type == "lang") {
                ctx.lang.iso = mutation.payload;
            }
        });
    },
    beforeUnmount() {
        this.subscribe();
    },
};
</script>

<style>
@import url("../../assets/scss/home/stats.css");
</style>

<template>
    <div class="stats">
        <div class="card">
            <div class="title">{{ Translate("actif_member") }}</div>
            <div class="bottom">
                <div class="number">{{ stats.user }}</div>
                <div class="icone">
                    <img :src="require('../../assets/image/user_wumpus.png')" />
                </div>
            </div>
        </div>
        <div class="card">
            <div class="title">
                {{ Translate("bot_count") }}
            </div>
            <div class="bottom">
                <div class="number">50,000</div>
                <div class="icone">
                    <img :src="require('../../assets/image/wumpus_a.png')" />
                </div>
            </div>
        </div>
        <div class="card">
            <div class="title">{{ Translate("payload_count") }}</div>
            <div class="bottom">
                <div class="number">50,000</div>
                <div class="icone">
                    <img
                        :src="require('../../assets/image/hammer.svg')"
                        style="transform: rotate(124deg)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>