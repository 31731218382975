<script>
import Stats from "./home/stats.vue";

export default {
    components: {
        Stats,
    },
    mounted() {
        
    },
};
</script>

<style>
@import url("../assets/scss/home/home.css");
</style>

<template>
    <section class="home">
        <div class="logo" v-on:click="$router.push({name: 'Home'})" style="cursor: pointer">
            <img :src="require('@/assets/image/logo.svg')" />
        </div>
        <Stats></Stats>
    </section>
</template>