<script>
import ServersSideBar from "@/components/sidebar/servers-sidebar.vue";
import BotSideBar from "@/components/sidebar/bot-sidebar.vue";
import NavBar from "@/components//navbar/navbar.vue";
import Home from "@/components/home.vue";

export default {
    components: {
        ServersSideBar,
        BotSideBar,
        NavBar,
        Home,
    },
    data() {
        return {
            mode: 0,
            subscribe: null,
        };
    },
    async mounted() {
        var ctx = this;
        document.title = "DistroyMe | Accueil";
        await ctx.me();

        // ctx.subscribe = ctx.$store.subscribe((mutation) => {
		// 	if (mutation.type == "lang") {
		// 		ctx.lang 
		// 	}
		// });
    },
    beforeUnmount()
    {
        // this.subscribe();
    }
};
</script>


<template>
    <div>
        <ServersSideBar></ServersSideBar>
        <BotSideBar></BotSideBar>
        <NavBar></NavBar>
        <Home></Home>
    </div>
</template>